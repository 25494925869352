import React, { useState, useEffect } from "react";
import { gapi } from "gapi-script";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import { Box, Modal, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { api } from "../utils/axios-instance";
import { auth } from "../helper/firebaseClient";
import toast, { Toaster } from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";
import useAuth from "../stores/authStore";

const CLIENT_ID =
  "432922979523-qhbojh5enmiqbh41gqmkokja9ktddnq4.apps.googleusercontent.com";
// const CLIENT_SECRET = "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";
const API_KEY = "AIzaSyCAbFthPbOb1-9ZNFSHXccrcmqs4OqBgzk";

const SCOPES = [
  "https://www.googleapis.com/auth/drive.file",
  "https://www.googleapis.com/auth/drive",
];

const OpenWithGoogle = ({
  presignedUrl,
  fileName,
  mimeType,
  driveLink,
  driveFileId,
  firestoreFileId,
  owner,
}) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");
  const [editorUrl, setEditorUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const profileData = useAuth((state) => state.profileData);

  useEffect(() => {
    const initClient = () => {
      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance) {
        // If authInstance already exists, just use it
        setIsSignedIn(authInstance.isSignedIn.get());
        authInstance.isSignedIn.listen(setIsSignedIn);
      } else {
        // Otherwise, initialize it
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES.join(" "),
            discoveryDocs: [
              "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
            ],
          })
          .then(() => {
            const authInstance = gapi.auth2.getAuthInstance();
            setIsSignedIn(authInstance.isSignedIn.get());
            authInstance.isSignedIn.listen(setIsSignedIn);
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error initializing GAPI client:", error);
          });
      }
    };

    gapi.load("client:auth2", initClient);
  }, []);

  const handleAuthClick = (additionalScopes) => {
    setLoading(true);
    const authInstance = gapi.auth2.getAuthInstance();

    if (authInstance.isSignedIn.get()) {
      // If signed in, check if additional scopes are needed

      const currentScopes = gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getGrantedScopes()
        .split(" ");

      const hasAllScopes = SCOPES.every((scope) =>
        currentScopes.includes(scope)
      );

      if (!hasAllScopes) {
        // Re-authenticate with additional scopes
        authInstance.signIn({ scope: SCOPES.join(" ") }).then(() => {
          setIsSignedIn(true);
          uploadFileToGoogleDrive();
        });
      } else {
        uploadFileToGoogleDrive();
      }
    } else {
      // First-time sign-in with required scopes
      authInstance.signIn({ scope: SCOPES.join(" ") }).then(() => {
        setIsSignedIn(true);
        uploadFileToGoogleDrive();
      });
    }
  };

  const uploadFileToGoogleDrive = () => {
    if (
      driveLink &&
      driveFileId &&
      firestoreFileId &&
      profileData.id === owner
    ) {
      setEmbedUrl(`https://drive.google.com/file/d/${driveFileId}/preview`);
      setEditorUrl(driveLink);
      openInGoogleEditor(driveFileId);
      return;
    }
    fetch(presignedUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const metadata = {
          name: fileName,
          mimeType: mimeType,
        };

        const accessToken = gapi.auth.getToken().access_token;

        const formData = new FormData();
        formData.append(
          "metadata",
          new Blob([JSON.stringify(metadata)], { type: "application/json" })
        );
        formData.append("file", blob);

        return fetch(
          "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${accessToken}`,
            }),
            body: formData,
          }
        );
      })
      .then((response) => response.json())
      .then(async (data) => {
        const fileId = data.id;
        const embedLink = `https://drive.google.com/file/d/${fileId}/preview`;
        setEmbedUrl(embedLink);
        setFilePermissions(fileId);
        openInGoogleEditor(fileId);

        const driveLink = generateEditLink(fileId, mimeType);
        const idToken =
          auth.currentUser && (await auth.currentUser.getIdToken());

        const res = await api.post(`/file/add_drive_link/`, {
          idToken,
          firestoreFileId,
          driveLink,
          driveFileId: fileId,
          syncFile: true,
        });

        if (res.data.success) {
          toast.success("File uploaded to Google Drive successfully");
        } else {
          setLoading(false);
          toast.error("Failed to upload file to Google Drive");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error uploading file:", error);
      });
  };

  const openInGoogleEditor = (fileId) => {
    const editorLink = generateEditLink(fileId, mimeType);
    console.log("Editor URL:", editorLink);
    setEditorUrl(editorLink);
    // Open the link in new tab
    setLoading(false);
    handleOpen();
  };

  const setFilePermissions = (fileId) => {
    const accessToken = gapi.auth.getToken().access_token;

    fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/permissions`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        role: "reader",
        type: "anyone",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            throw new Error(err.error.message);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error setting file permissions:", error);
      });
  };

  const generateEditLink = (fileId, mimeType) => {
    let baseLink = `https://docs.google.com/`;

    if (mimeType.includes("spreadsheet")) {
      baseLink += `spreadsheets/d/${fileId}/edit`;
    } else if (mimeType.includes("document")) {
      baseLink += `document/d/${fileId}/edit`;
    } else if (mimeType.includes("presentation")) {
      baseLink += `presentation/d/${fileId}/edit`;
    } else {
      baseLink = `https://drive.google.com/file/d/${fileId}/edit`;
    }

    return baseLink;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Toaster position="bottom-left" reverseOrder={false} />
      <EmbedModal
        embedUrl={embedUrl}
        open={open}
        handleClose={handleClose}
        editorUrl={editorUrl}
      />

      <LoadingButton
        loading={loading}
        variant="outlined"
        onClick={handleAuthClick}
        sx={{ borderRadius: "20px", textTransform: "none" }}
        endIcon={<GoogleIcon fontSize="small" />}
      >
        {isSignedIn ? "Open in" : "Connect"}
      </LoadingButton>
    </div>
  );
};

const EmbedModal = ({ embedUrl, open, handleClose, editorUrl }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="embed-modal-title"
      aria-describedby="embed-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="embed-modal-title" variant="h6" component="h2">
          Google Drive Embed
        </Typography>
        <iframe
          src={embedUrl}
          width="100%"
          height="300px"
          allow="autoplay"
          title="Google Drive Embed"
          style={{ border: "none", marginTop: "20px" }}
        ></iframe>
        <Box textAlign="center" mt={2}>
          <a href={editorUrl} target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: "20px", textTransform: "none" }}
            >
              Edit with Google
            </Button>
          </a>
        </Box>
      </Box>
    </Modal>
  );
};

export default OpenWithGoogle;
