import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { api } from "../utils/axios-instance";
import { RenameFolder } from "./RenameFolder";

// images from public folder
const ThreeDots = `${process.env.PUBLIC_URL}/assets/threedots.webp`;
const FolderImg = `${process.env.PUBLIC_URL}/assets/folder.webp`;

const OwnedFolders = ({ folders, listFolders }) => {
  const [folder, setFolder] = useState({});
  const [anchorEls, setAnchorEls] = useState({}); // Separate state for anchorEls

  const handleMenuClick = (event, folder) => {
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [folder.id]: event.currentTarget,
    }));
    setFolder(folder);
  };

  const handleClose = (folderId) => {
    setAnchorEls((prevAnchorEls) => ({
      ...prevAnchorEls,
      [folderId]: null,
    }));
  };

  const deleteFolder = async (folder_id) => {
    try {
      const response = await api.delete(`/file/folder/${folder_id}`);
      if (response.status === 204) {
        handleClose(folder_id);
        toast.success("Folder deleted successfully.");
        listFolders();
      }
    } catch (error) {
      console.log("error occured while deleting folder.", error);
      toast.error("Error occurred while deleting the folder");
    }
  };

  return (
    <div className="mt-2">
      <Toaster position="bottom-left" reverseOrder={false} />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 ">
        {folders &&
          folders.map((folder) => (
            <div
              key={folder.id}
              style={{
                backgroundColor: folder.metadata?.bg
                  ? folder.metadata?.bg
                  : "#fff",
              }}
              className="border rounded-2xl cursor-pointer flex-shrink-0 mr-4"
            >
              <div>
                <Link
                  className="flex justify-center items-center py-4 px-8"
                  to={`/filesInsideFolder/${folder.name}/${folder.id}`}
                >
                  <img alt="folder img" className="h-24 w-24" src={FolderImg} />
                </Link>
              </div>
              <span className="flex flex-row justify-between items-center line-clamp-1 ">
                <p className="px-4 pb-2 line-clamp-1 font-semibold text-md">
                  {folder.name}
                </p>
                <span>
                  <button
                    className="mx-2"
                    onClick={(event) => handleMenuClick(event, folder)}
                  >
                    <img src={ThreeDots} height={25} width={25} alt="" />
                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEls[folder.id]}
                    open={Boolean(anchorEls[folder.id])}
                    onClose={() => handleClose(folder.id)}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      style: {
                        border: "1px solid [#11182626]",
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.1)",
                        borderRadius: "6px",
                      },
                    }}
                  >
                    <MenuItem style={{ padding: "0px 10px" }}>
                      <button
                        className="text-red-500"
                        onClick={() => deleteFolder(folder.id)}
                      >
                        Delete
                      </button>
                    </MenuItem>
                    <MenuItem style={{ padding: "0px 10px" }}>
                      <RenameFolder folder={folder} />
                    </MenuItem>
                  </Menu>
                </span>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OwnedFolders;
